import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { ILogger, LoggerSymbol, ViewerModel, ViewerModelSym } from '@wix/thunderbolt-symbols'
import { OOIComponentLoader } from './types'
import { MODULE_URL } from './constants'

async function loadRequireJS(moduleRepoUrl: string = MODULE_URL, logger: ILogger) {
	await new Promise((resolve, reject) => {
		const script = document.createElement('script')
		script.src = `${moduleRepoUrl}/requirejs-bolt@2.3.6/requirejs.min.js`
		script.onload = resolve
		script.onerror = reject
		document.head.appendChild(script)
	})
	// @ts-ignore
	window.define('lodash', [], () => _)
	// @ts-ignore
	window.define('reactDOM', [], () => ReactDOM)
	// @ts-ignore
	window.define('react', [], () => React)

	// @ts-ignore
	window.requirejs.onError = (error) => {
		const { requireModules, requireType } = error
		logger.captureError(error, {
			tags: { feature: 'ooi', methodName: 'requirejs.onError' },
			extra: { requireModules, requireType },
		})
	}
}

// eslint-disable-next-line prettier/prettier
export default withDependencies([ViewerModelSym, LoggerSymbol], ({ siteAssets }: ViewerModel, logger: ILogger): OOIComponentLoader => {
		let waitForRequireJsToLoad: Promise<unknown> | null = null
		return {
			loadComponent(componentUrl: string) {
				return new Promise(async (resolve, reject) => {
					waitForRequireJsToLoad =
						waitForRequireJsToLoad || loadRequireJS(siteAssets.clientTopology.moduleRepoUrl, logger)
					await waitForRequireJsToLoad
					__non_webpack_require__(
						[componentUrl],
						(module: any) => resolve(module?.default?.component),
						reject
					)
				})
			},
		}
	}
)
